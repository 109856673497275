import React from 'react';
import axios from 'axios';
import moment from 'moment';
import classnames from 'classnames';
import _ from 'lodash'
import { useEffect, useRef } from 'react'

import * as Constants from './constants'

export const Header = {
  'Content-Type': 'application/json'
}

const getAccessToken = () => {
  let user = JSON.parse(localStorage.getItem(APP_NAME))
  if (user && user[Constants.COCRM_USER].jwtToken) {
    return user[Constants.COCRM_USER].jwtToken
  } else {
    console.warn('No token found')
    return null
  }
}

export function capitalizeFirstLetter(string) {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return string
}

export function getPaidTag(user) {
  let isPaid = false
  if (user.tags && user.tags.length > 0) {
    isPaid = user.tags.includes('paid')
  }
  return isPaid
}

export function formatTime(time, format) {
  return moment.parseZone(time).format(format)
}
export function formatDate(date, format) {
  return moment(date).format(format);
}

export function isNumber(value) {
  let regex = /^[0-9]+$/;
  return regex.test(value)
}

export const getAuthHeader = () => {
  let header = Header
  if (getAccessToken()) {
    header = Object.assign({}, Header, {
      Authorization: 'Bearer: ' + getAccessToken(),
      'X-Crm-App-Version': APP_VERSION
    })
  }
  return header
}

export const getWithoutAuthHeader = () => {
  let header = Header
  if (getAccessToken()) {
    header = Object.assign({}, Header, {
      // Authorization: 'Bearer: ' + getAccessToken(),
      'X-Crm-App-Version': APP_VERSION
    })
  }
  return header
}

export function ajax(url, method, data = null, appendBaseUrl = true, signal = null, appendAuth = true, withCredentials = false) {
  return new Promise((resolve, reject) => {
    let apiUrl = url
    if (appendBaseUrl)
      apiUrl = APP_API_URL + url

    const headers = getAuthHeader()

    var options = {}
    if (appendAuth) {
      options = {
        url: apiUrl,
        method: method,
        headers
      }
    } else {
      options = {
        url: apiUrl,
        method: method,
      }
    }

    if (data) {
      options.data = data
    }

    if (signal) {
      options.signal = signal
    }

    if (withCredentials) {
      options.withCredentials = true
    }

    axios(options)
      .then(res => {
        switch (res.status) {
          case 500:
            console.error('Some server error')
            reject(res)
            break
          case 401:
            console.error('Unauthorized')
            window.location.reload()
            localStorage.clear()
            reject(res)
            break
          case 400:
            console.warn('Bad Request')
            reject(res)
            break
          case 418:
            // versionupdate().openModal(res)
            break
          default:
            break
        }
        resolve(res.data)
      })
      .catch(err => {
        if (err && err.response && err.response.status === 401) {
          console.error('Unauthorized')
          window.location.reload()
          localStorage.clear()
        }
        reject(err)
      })
  })

}

export function getRecurTable(recur) {
  let table =
    <table>
      <tbody>
        <tr>
          <td>

            <b className={'text-success'} >
              {' '}{recur.credits}/{recur.type}
            </b>
          </td>
        </tr>
        <tr>
          <td>
            {formatTime(recur.recur_credit_reset_date)}
          </td>
        </tr>
      </tbody>
    </table>
  return table
}
//linechart
export function pickChartColor(index) {
  if (index > Constants.GRAPH_COLORS.length - 1) {
    index = index % (Constants.GRAPH_COLORS.length - 1)
  }
  let color = Constants.GRAPH_COLORS[index]
  return color
}

export function pickAreaChartColor(index) {
  if (index > Constants.GRAPH_AREA_COLORS.length - 1) {
    index = index % (Constants.GRAPH_AREA_COLORS.length - 1)
  }
  let color = Constants.GRAPH_AREA_COLORS[index]
  return color
}

export function getSubsTable(subs) {
  return (
    <div>
      <p
        className={classnames('subscription-plan fw-bold mb-0', {
          'text-success': subs.status === 'active',
          'text-danger': subs.status !== 'active'
        })}
      >
        {subs.plan_name}
      </p>
      <p className='mb-0'>
        ${Number(subs.plan_price).toLocaleString()} / {subs.plan_type}
      </p>
      <p className='mb-0'>
        {Number(subs.credits).toLocaleString()} Credits
      </p>
    </div>
  )
}

export function getCoSubsTable(subs) {
  return (
    <div>
      <p
        className={classnames('subscription-plan fw-bold m-0', {
          'text-success': subs.status === 'active',
          'text-danger': subs.status !== 'active'
        })}
      >
        {subs.product_name}
      </p>
      <span className='m-0'>
        {Constants.CURRENCY[subs.price.currency].symbol}
        {Number(subs.price.total_price).toLocaleString(Constants.CURRENCY[subs.price.currency].locale)}
        {
          subs.product_renewal
            ? <span> / {getRenewalUnit(subs.product_renewal)} </span>
            : null
        }
      </span>
      <span className='m-0'>
        ({Number(subs.credits).toLocaleString()} Credits)
      </span>
    </div>
  )
}

export function getCoLastSubsTable(subs) {
  if (!subs)
    return '-'

  return (
    <>
      <p
        className={classnames('subscription-plan fw-bold m-0', {
          'text-success': subs.status === 'active',
          'text-danger': subs.status !== 'active'
        })}
      >
        {_.startCase(subs.product_name)}
      </p>
      <p className='m-0'>
        {subs.purchase_details.amount_details.currency_symbol}
        {Number(subs.purchase_details.amount_details.total_price)
          .toLocaleString(Constants.CURRENCY[subs.purchase_details.amount_details.currency].locale)}
        {
          subs.renewal_cycle
            ? <span> / {getRenewalUnit(subs.renewal_cycle)} </span>
            : null
        }
      </p>
      <p className='m-0'>
        {formatDate(subs.cancel.cancelled_at, 'DD-MMM-YYYY @ h:mm:ss a')}
      </p>
    </>
  )
}

export function calculateConfidenceLevelTagFromScore(confidence_score) {
  let confidenceLevel
  // console.log(confidence_score)
  if (confidence_score) {
    if (confidence_score > 0 && confidence_score <= 30) confidenceLevel = 'Very Low'
    else if (confidence_score > 30 && confidence_score <= 60) confidenceLevel = 'Low'
    // else if (confidence_level > 60 && confidence_level <= 60) confidence_text = 'Medium'
    else if (confidence_score > 60 && confidence_score <= 80) confidenceLevel = 'Moderate'
    else if (confidence_score > 80) confidenceLevel = 'High'
    else confidenceLevel = '-'
  } else { // For backward compatibility sakes
    confidenceLevel = '-'
  }

  return confidenceLevel
}

export function getColorTagFromConfidenceLevel(confidence_level) {
  let color

  switch (confidence_level) {
    case 'Very Low':
      color = 'red'
      break
    case 'Low':
      color = 'coral'
      break
    case 'Moderate':
      color = 'blue'
      break
    case 'High':
      color = 'green'
      break
    default:
      color = 'orange'
  }
  return color
}

export function getChannelSource(sources) {
  return sources.map((source, index) => {
    return (
      <div
        key={index}
      >
        {source.source ? (
          <p className='text-capitalize'>
            {source.source}
          </p>
        ) : '-'}
      </div>
    )
  })
}

export function getChannelSourceCampaign(sources) {
  return sources.map((source, index) => {
    return (
      <div
        key={index}
      >
        {source.campaign ? (
          <p>
            {source.campaign}
          </p>
        ) : '-'}
      </div>
    )
  })
}

export function getChannelQuery(sources) {
  return sources.map((source, index) => {
    return (
      <div
        key={index}
      >
        {source.url_params ? (
          <p>
            <span className='channel_source'>{source.url_params}</span>
          </p>
        ) : '-'}
      </div>
    )
  })
}

export function isAlphaNumeric(value) {
  let regex = /^[a-zA-Z0-9]*$/g;
  return regex.test(value)
}
export function getRemainingCredits(credits) {
  let subs = credits.subs ? credits.subs : null
  let available = credits.available ? credits.available : null
  let total = subs + available
  return Number(total).toLocaleString()
}


export function removeUnderscore(inputString) {
  return inputString.replace(/_/g, ' ');
}

const getRenewalUnit = (params) => {
  if (params) {
    let { unit, count } = params

    for (let [renewalUnit, renewalInfo] of _.entries(Constants.RENEWAL_UNITS)) {
      let matchingRenewalUnit = _.find(
        renewalInfo.patterns,
        { unit, count }
      )

      if (!_.isEmpty(matchingRenewalUnit)) {
        return renewalUnit
      }
    }
  }
  return ''
}

export function timeAgo(timestamp) {
  const now = new Date();
  const past = new Date(timestamp);
  const diffInSeconds = Math.floor((now - past) / 1000);

  const minutes = Math.floor(diffInSeconds / 60);
  const hours = Math.floor(diffInSeconds / 3600);
  const days = Math.floor(diffInSeconds / 86400);

  if (days > 0) {
    return `${days} day${days > 1 ? 's' : ''} ago`;
  } else if (hours > 0) {
    return `${hours} hour${hours > 1 ? 's' : ''} ago`;
  } else if (minutes > 0) {
    return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
  } else {
    return 'Just now';
  }
}

export function validateEmailAddress(email) {
  let regex = /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email)
}

export function isAlphabet(value) {
  let regex = /^[a-zA-Z\s]*$/g
  return regex.test(value)
}

export function getResultAsReqFormat(result, metric) {
  if (Constants.RESULTS[result].type === 'percentage') {
    if (metric && metric.value !== null && (!metric.found && metric.found !== 0)) {
      let val = Number(metric.value).toLocaleString()
      let percent = metric.value ? ' (' + Number(metric.percentage) + '%)' : ''
      return val + percent
    } else if (metric && metric.found !== null) {
      let val = Number(metric.found).toLocaleString()
      let percent = isNumber(metric.found) ? ' (' + Number(metric.percentage) + '%)' : ''
      return val + percent
    } else {
      return '-'
    }
  } else if (Constants.RESULTS[result].type === 'text') {
    return isAlphabet(metric) ? metric : '-'
  } else {
    return isNumber(metric) ? Number(metric).toLocaleString() : '-'
  }
}

export function getFileTypeImage(brand) {
  let image = 'csv'
  switch (brand) {
    case 'csv':
      image = require('../static/img/integrations-icons/csv.png')
      break
    case 'xlsx':
      image = require('../static/img/integrations-icons/xlsx.png')
      break
    case 'mailchimp':
      image = require('../static/img/integrations-icons/mailchimp.png')
      break
    case 'leads':
      image = require('../static/img/integrations-icons/leads.png')
      break
    case 'upload':
      image = require('../static/img/integrations-icons/upload.png')
      break
    case 'active_campaign':
      image = require('../static/img/integrations-icons/active_campaign.png')
      break
    case 'moosend':
      image = require('../static/img/integrations-icons/moosend.png')
      break
    case 'apollo':
      image = require('../static/img/integrations-icons/apollo.png')
      break
    case 'hubspot':
      image = require('../static/img/integrations-icons/hubspot.png')
      break
    case 'mailerlite':
      image = require('../static/img/integrations-icons/mailerlite.png')
      break
    case 'sendgrid':
      image = require('../static/img/integrations-icons/sendgrid.png')
      break
    case 'clevertap':
      image = require('../static/img/integrations-icons/clevertap.png')
      break
    case 'lemlist':
      image = require('../static/img/integrations-icons/lemlist.png')
      break
    case 'go_high_level':
      image = require('../static/img/integrations-icons/go_high_level.png')
      break
    case 'prospect_people':
    case 'person':
      image = require('../static/img/integrations-icons/prospect_people.png')
      break
    case 'prospect_company':
    case 'company':
      image = require('../static/img/integrations-icons/prospect_company.png')
      break
    default:
      image = require('../static/img/integrations-icons/csv.png')
      break
  }
  return image
}

export function getDeliveryStatusFromDeliverableScore(deliverable_score) {
  let status = {}
  if (deliverable_score === 100) {
    status.name = 'No Risk'
    status.value = 'no_risk'
  } else if (deliverable_score > 75) {
    status.name = 'Low Risk'
    status.value = 'no_risk'
  } else if (deliverable_score > 50) {
    status.name = 'Moderate Risk'
    status.value = 'moderate_risk'
  } else {
    status.name = 'High Risk'
    status.value = 'high_risk'
  }
  return status
}

export function truncateMiddle(str, maxLength) {
  if (str.length <= maxLength) {
    return str;
  }

  const keep = Math.floor(maxLength / 2);
  const start = str.slice(0, keep);
  const end = str.slice(-keep);

  return `${start}...${end}`;
}

export function getResultExpiresOnTime(created_on) {
  const diffInMins = Math.round(moment.duration(created_on).asMinutes());
  if (diffInMins > 1) {
    if (diffInMins > 60 && diffInMins < 1440) {
      const hours = moment.duration(created_on).hours();
      const minutes = Math.round(diffInMins - hours * 60);
      return `${hours} hour(s) ${minutes} minute(s)`;
    } else if (diffInMins > 1440) {
      const days = Math.floor(moment.duration(created_on).asDays());
      const hours = Math.floor((diffInMins - (days * 24 * 60)) / 60);
      return `${days} day(s) ${hours} hour(s)`;
    }
  }
  return moment.duration(created_on).humanize();
}

export function getListCreatedOnTime(created_on) {
  let diffInMins = moment.duration(created_on).asMinutes();
  diffInMins = Math.round(diffInMins);
  let time = diffInMins > 1 ? ' minutes ' : ' minute ';
  let readableTime = diffInMins + time + ' ';
  if (diffInMins > 1) {
    if (diffInMins > 60 && diffInMins < 1440) {
      const hours = moment.duration(created_on).hours();
      const minutes = Math.round(diffInMins - hours * 60);
      time = hours > 1 ? ' hours ' : ' hour ';
      readableTime = hours + time + minutes + ' minutes ';
    } else if (diffInMins > 1440) {
      const days = Math.floor(moment.duration(created_on).asDays());
      const hours = Math.floor((diffInMins - (days * 24 * 60)) / 60);
      const minutes = Math.round(diffInMins - (days * 24 * 60) - (hours * 60));
      time = days > 1 ? ' days ' : ' day ';
      readableTime = days + time + hours + ' hours ' + minutes + ' minutes';
    }
    return readableTime;
  } else {
    return moment.duration(created_on).humanize();
  }
}


export const usePrevious = (value) => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export function checkAndFormatDateAndTime(dateAndTime, humanized = false) {
  if (humanized)
    return getHumanizedTime(dateAndTime)

  let formattedDateAndTime = dateAndTime
  formatTime(new Date(), 'YYYY') === formatTime(dateAndTime, 'YYYY')
    ? formattedDateAndTime = formatTime(dateAndTime, ' DD MMM hh:mm A')
    : formattedDateAndTime = formatTime(dateAndTime, ' DD MMM YYYY hh:mm A')
  return formattedDateAndTime
}

export function getHumanizedTime(value) {
  let diffInMins = moment.duration(value).asMinutes()
  diffInMins = Math.round(diffInMins)
  let time = diffInMins > 1 ? ' minutes ' : ' minute '
  let readableTime = diffInMins + time + ' '
  if (diffInMins > 1) {
    if (diffInMins > 60 && diffInMins < 1440) {
      let hours = moment.duration(value).hours()
      let minutes = Math.round(diffInMins - hours * 60)
      time = hours > 1 ? ' hours ' : ' hour '
      readableTime = hours + time + minutes + ' minutes '
    }
    else if (diffInMins > 1440) {
      let days = Math.floor(moment.duration(value).asDays())
      let hours = Math.floor((diffInMins - (days * 24 * 60)) / 60)
      // let minutes = Math.round(diffInMins - (days*24*60) - (hours*60))
      time = days > 1 ? ' days ' : ' day '
      readableTime = days + time + hours + ' hours '
    }
    return readableTime
  } else {
    return moment.duration(value).humanize()
  }
}

