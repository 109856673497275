import * as Constants from './constant' 

export function formatNumberToXDecimalPlace(number, x) {
  const DOT = '.'
  number = Math.round(number * 1e10) / 1e10
  number = number.toString()
  let indexOfDot = number.indexOf(DOT)

  if (indexOfDot !== -1) {
    number = number.slice(0, indexOfDot + x + 1)
  }

  return +number
}

export const calculateAddonAmount = (product, quantity) => {
  const totalCount = product.limits.count;
  const price = product.price.total_price;
  const totalQuantity = quantity / totalCount;
  return formatNumberToXDecimalPlace(price * totalQuantity, 2);
};


export const filterProductsByBilling = (products, billingType) => {
  return products.filter(product => {
    const { recurring, renewal } = product;

    switch (billingType) {
      case 'one-time':
        return !recurring;

      case 'monthly': {
        const patterns = Constants.RENEWAL_UNITS.month.patterns;
        return recurring && patterns.some(pattern => pattern.unit === renewal.unit && pattern.count === renewal.count);
      }

      case 'annual': {
        const patterns = Constants.RENEWAL_UNITS.year.patterns;
        return recurring && patterns.some(pattern => pattern.unit === renewal.unit && pattern.count === renewal.count);
      }

      default:
        return false; // Default case for unsupported billingType
    }
  })
}