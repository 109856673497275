import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { OverlayTrigger, Tooltip, Collapse } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import flags from 'react-phone-number-input/flags'

import * as Helper from '../../../../helpers/helper';
import * as Constants from '../../../../helpers/constants';

import ClipBoardCopy from '../../../widgets/clipboard_copy';
import ClearoutIndividualUserInfo from '../../../individual_user_info/clearout_individual_user_info';
import ShowMoreButton from '../../../widgets/show_more_button'

const FileDetails = (props) => {
  const [fileDetails, setFileDetails] = useState({
    created_on: props.createdOn,
    file_type: props.fileType,
    time_taken: props.timeTaken,
    list_id: props.listId,
    mode: props.mode,
    last_verified_on: props.lastVerifiedOn,
    last_cancelled_on: props.lastCancelledOn,
    account_details: props.accountDetails,
    list_name: props.listName,
    userDetails: props.userDetails,
    verifiedOn: props.verifiedOn,
    action_inprogress: props.action_inprogress,
    progress_updated_on: props.progressUpdatedOn,
    result_expires_on: props.resultExpiresOn,
  });
  const [showModal, setShowModal] = useState(false)
  const [showMore, setShowMore] = useState(false)
  const enrichDetails = _.get(props, 'individualList.enrich_details', null)
  const enrichProgress = _.get(props, 'individualList.enrich_progress', null)
  const lastExportedOn = _.get(props, 'individualList.last_exported_on', null)

  useEffect(() => {
    setFileDetails(prevDetails => ({
      ...prevDetails,
      created_on: props.createdOn,
      file_type: props.fileType,
      time_taken: props.timeTaken,
      list_id: props.listId,
      mode: props.mode,
      last_verified_on: props.lastVerifiedOn,
      last_cancelled_on: props.lastCancelledOn,
      account_details: props.accountDetails,
      list_name: props.listName,
      verifiedOn: props.verifiedOn,
      action_inprogress: props.action_inprogress,
      progress_updated_on: props.progressUpdatedOn,
      result_expires_on: props.resultExpiresOn,
    }));
  }, [props]);

  const handleShowModal = () => {
    setShowModal(true)
  };

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const toggleShowMore = () => {
    setShowMore(!showMore)
  };

  const fileImg = Helper.getFileTypeImage(fileDetails.file_type);
  const isPaid = fileDetails.userDetails && fileDetails.userDetails.tags ? Helper.getPaidTag(fileDetails.userDetails) : null;
  const defaultCountryCode = _.get(fileDetails, 'account_details.default_country_code', null);
  const settings = _.get(props.individualList, 'settings', null);

  return (
    <div className='w-100'>
      <div className="d-flex align-items-center mb-2">
        <img
          className="list-uploaded-file-img"
          src={fileImg}
          alt={fileDetails.list_name}
        />
        <p
          className="file-name"
          title={fileDetails.list_name}
        >
          {Helper.truncateMiddle(fileDetails.list_name, 14)}
        </p>
        {fileDetails.result_expires_on === null || fileDetails.result_expires_on > 0 ?
          <OverlayTrigger
            trigger={['hover', 'click']}
            placement="right"
            overlay={
              <Tooltip
                id="tooltipText"
                className='position-fixed'
              >
                {'Download ' + fileDetails.list_name}
              </Tooltip>
            }
          >
            <button
              className="btn btn-link d-inline btn-input-file-download"
              style={{ padding: '0' }}
              target="blank"
              onClick={() => props.downloadInputFile(fileDetails.list_id)}
            >
              <i
                className="fa fa-download pr-1"
                style={{ paddingLeft: '5px' }}
              >
              </i>
            </button>
          </OverlayTrigger>

          : null}
      </div>
      {Constants.INTEGRATION_PLATFORMS.includes(fileDetails.file_type) ? (
        <div className="d-flex justify-content-between">
          <span className="show-more-settings-metric">Account Name</span>
          <span className="deliverability-value">
            {fileDetails.account_details.account_name}
          </span>
        </div>
      ) : null}
      {Constants.INTEGRATION_PLATFORMS.includes(fileDetails.file_type) ? (
        <div className="d-flex justify-content-between">
          <span className="show-more-settings-metric">Account ID</span>
          <span className="deliverability-value">
            {fileDetails.account_details.account_id}
          </span>
        </div>
      ) : null}
      <div className="d-flex justify-content-between">
        <span className="show-more-settings-metric">Created On</span>
        <span className="deliverability-value">
          {(Helper.formatTime(new Date(), 'YYYY') === Helper.formatTime(fileDetails.created_on, 'YYYY'))
            ? Helper.formatTime(fileDetails.created_on, ' DD MMM hh:mm A')
            : Helper.formatTime(fileDetails.created_on, ' DD MMM YYYY hh:mm A')}
        </span>
      </div>
      {enrichDetails && enrichDetails.ended_on !== null ? (
        <>
          <div className="d-flex justify-content-between">
            <span className="show-more-settings-metric">Last Enrich Started On</span>
            <span className="deliverability-value">
              {(Helper.formatTime(new Date(), 'YYYY') === Helper.formatTime(enrichDetails.started_on, 'YYYY'))
                ? Helper.formatTime(enrichDetails.started_on, ' DD MMM hh:mm A')
                : Helper.formatTime(enrichDetails.started_on, ' DD MMM YYYY hh:mm A')}
            </span>
          </div>
          <div className="d-flex justify-content-between">
            <span className="show-more-settings-metric">Last Enriched On</span>
            <span className="deliverability-value">
              {(Helper.formatTime(new Date(), 'YYYY') === Helper.formatTime(enrichDetails.ended_on, 'YYYY'))
                ? Helper.formatTime(enrichDetails.ended_on, ' DD MMM hh:mm A')
                : Helper.formatTime(enrichDetails.ended_on, ' DD MMM YYYY hh:mm A')}
            </span>
          </div>
        </>
      ) : null}
      {
        !_.isEmpty(enrichDetails) && enrichDetails.time_taken
          ?
          <div className="d-flex justify-content-between">
            <span className="show-more-settings-metric">Last Enrich Time Taken</span>
            <span className="deliverability-value">
              {Helper.checkAndFormatDateAndTime(enrichDetails.time_taken, true)}
            </span>
          </div>
          : null
      }
      {
        !_.isEmpty(enrichProgress) && enrichProgress.updated_on
          ?
          <div className="d-flex justify-content-between">
            <span className="show-more-settings-metric">Enrich Progress Updated On</span>
            <span className="deliverability-value">
              {enrichProgress.updated_on}
            </span>
          </div>
          : null
      }
      {
        !_.isEmpty(lastExportedOn) ? (
          <div className="d-flex justify-content-between">
            <span className="show-more-settings-metric">Exported On:</span>
            <span className="deliverability-value">
              {(Helper.formatTime(new Date(), 'YYYY') === Helper.formatTime(lastExportedOn, 'YYYY'))
                ? Helper.formatTime(lastExportedOn, ' DD MMM hh:mm A')
                : Helper.formatTime(lastExportedOn, ' DD MMM YYYY hh:mm A')}
            </span>
          </div>
        ) : null
      }
      {
        fileDetails.verifiedOn ? (
          <div className="d-flex justify-content-between">
            <span className="show-more-settings-metric">Started On</span>
            <span className="deliverability-value">
              {(Helper.formatTime(new Date(), 'YYYY') === Helper.formatTime(fileDetails.verifiedOn, 'YYYY'))
                ? Helper.formatTime(fileDetails.verifiedOn, ' DD MMM hh:mm A')
                : Helper.formatTime(fileDetails.verifiedOn, ' DD MMM YYYY hh:mm A')}
            </span>
          </div>
        ) : null
      }
      {
        fileDetails.last_verified_on ? (
          <div className="d-flex justify-content-between">
            <span className="show-more-settings-metric">
              {props.listComponent === 'email-finder' ? 'Processed On' : 'Verified On'}
            </span>
            <span className="deliverability-value">
              {(Helper.formatTime(new Date(), 'YYYY') === Helper.formatTime(fileDetails.last_verified_on, 'YYYY'))
                ? Helper.formatTime(fileDetails.last_verified_on, ' DD MMM hh:mm A')
                : Helper.formatTime(fileDetails.last_verified_on, ' DD MMM YYYY hh:mm A')}
            </span>
          </div>
        ) : null
      }
      {
        fileDetails.progress_updated_on ? (
          <div className="d-flex justify-content-between">
            <span className="show-more-settings-metric">Progress Updated On</span>
            <span className="deliverability-value">
              {fileDetails.progress_updated_on}
            </span>
          </div>
        ) : null
      }
      {
        fileDetails.last_cancelled_on ? (
          <div className="d-flex justify-content-between">
            <span className="show-more-settings-metric">Cancelled On</span>
            <span className="deliverability-value">
              {Helper.formatTime(fileDetails.last_cancelled_on, ' DD MMM hh:mm A')}
            </span>
          </div>
        ) : null
      }
      {
        fileDetails.action_inprogress === null && fileDetails.time_taken ? (
          <div className="d-flex justify-content-between">
            <span className="show-more-settings-metric">Time Taken</span>
            <span className="deliverability-value">
              {Helper.getListCreatedOnTime(fileDetails.time_taken)}
            </span>
          </div>
        ) : null
      }
      {
        fileDetails.userDetails !== null ? (
          <div className="d-flex justify-content-between">
            <span className="show-more-settings-metric">Name</span>
            <span className="deliverability-value">
              {fileDetails.userDetails.name}
            </span>
          </div>
        ) : null
      }
      {
        fileDetails.userDetails !== null ?
          <div className="d-flex justify-content-between">
            <span>
              Email
            </span>
            <span className='d-flex flex-row'>
              <a
                onClick={() => handleShowModal()}
                rel='noopener noreferrer'
                className='custom-link'
                style={{ cursor: 'pointer' }}
              >
                {_.truncate(fileDetails.userDetails.email, { 'length': 30, 'omission': '...' })}
              </a>
              <span className='ms-1'>
                <ClipBoardCopy
                  textToCopy={fileDetails.userDetails.email}
                />
              </span>
            </span>
          </div> : null
      }
      {
        defaultCountryCode !== null ? (
          <div className="d-flex justify-content-between">
            <span className="show-more-settings-metric">Default Country</span>
            <span className="cop-listdetails-flag-svg">
              {flags[defaultCountryCode].call(defaultCountryCode,
                { title: defaultCountryCode })}
            </span>
          </div>
        ) : null
      }
      <p className="paid-label mb-0">{isPaid ? <span className="label label-success">Paid</span> : null}</p>
      {
        props.service.NAME === 'email_verifier' && fileDetails.mode !== null || props.service.NAME === 'email_finder' ? (
          <div className='d-flex justify-content-center'>
            <ShowMoreButton
              showMore={showMore}
              onClick={toggleShowMore}
              showLabel="Show Settings"
              hideLabel='Hide Settings'
            />
          </div>
        ) : null
      }
      <Collapse in={showMore}>
        <div> {/* Wrap content in a <div> for `react-bootstrap` Collapse */}
          {
            props.service.NAME === 'email_verifier' ? (
              <div className="additional-metrics">
                <div className="metrics-row d-flex justify-content-between">
                  <span className="show-more-settings-metric">Optimized For</span>
                  <span className="deliverability-value">
                    {Constants.EMAIL_VERIFY_MODES[fileDetails.mode]}
                  </span>
                </div>
              </div>
            ) : props.service.NAME === 'email_finder' ? (
              <div className="additional-metrics">
                <div className="metrics-row d-flex flex-row justify-content-between">
                  <span className="show-more-settings-metric">Role Based Emails</span>
                  <span className="deliverability-value">
                    {settings && settings.include_role_email === false
                      ? 'Excluded'
                      : 'Included'}
                  </span>
                </div>
                <div className="metrics-row d-flex flex-row justify-content-between">
                  <span className="show-more-settings-metric">Domain Check</span>
                  <span className="deliverability-value">
                    {settings && settings.strict_domain_check === false
                      ? 'Relaxed'
                      : 'Strict'}
                  </span>
                </div>
              </div>
            ) : null
          }
        </div>
      </Collapse>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        size='xl'
      >
        <Modal.Header closeButton>
          <Modal.Title className='fs-6 fw-bold'>{fileDetails.userDetails.user_id} details</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-body-bg-color'>
          <ClearoutIndividualUserInfo
            client={props.client}
            userId={fileDetails.userDetails.user_id}
          />
        </Modal.Body>
      </Modal>
    </div >
  );
}

export default FileDetails;
