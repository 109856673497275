import React from 'react';
import CouponDropdown from '../../../widgets/custom_dropdown';
import * as ConstantsRenewal from './../../helpers/constant'
import * as Constants from './../../../../helpers/constants';


const CouponCodeDropdown = ({ onStateChange, couponOptions, coupon_code, selected_product }) => {
  const onCouponChange = (selectedOption) => {
    onStateChange('coupon_code', selectedOption ? selectedOption.value : ''); // Update coupon_code
  };

  const showCoupon = (() => {

    if (selected_product && selected_product.display_name == Constants.PRODUCT_DISPLAY_NAME.pay_as_you_go_credits) return true;

    if (!selected_product || !selected_product.renewal) return false;

    const renewal = selected_product.renewal;
    const patterns = ConstantsRenewal.RENEWAL_UNITS.year.patterns;

    return patterns.some(
      (pattern) => pattern.unit === renewal.unit && pattern.count === renewal.count
    )
  })();

  return (
    <>
      {showCoupon && (
        <div className="product-type-dropdown">
          <label>Select Coupon</label>
          <CouponDropdown
            options={couponOptions}
            placeholder={'Select Coupon'}
            value={couponOptions.find(option => option.value === coupon_code) || { value: '', label: 'Select a coupon' }}
            onSelectOption={onCouponChange}
            searchable={true}
          />

        </div>
      )}
    </>
  )
};


export default CouponCodeDropdown;
