import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash'
import { Table } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

import MarkAsPaid from './components/mark_as_paid_modal'
import * as IndividualUserConstants from '../../helpers/constant'
import * as UserInfoFormatters from '../../helpers/user_info_formatters'
import * as Constants from '../../../../helpers/constants'
import { ApiHelper } from '../../../../helpers/apihelper';
import showToastNotification from './../../../widgets/toastify';


const initialState = {
  invoiceData: []
}

const InvoicesAndReceipts = ({ data, getUserInfo, client }) => {
  const [state, setState] = useState(initialState)
  const jwtToken = useSelector((state) => state.user.jwtToken)

  const getInvoices = useCallback(() => {
    let requestBody = {
      co_user_id: data.user_id || data._id,
      cop_user_id: data.user_id,
      client_secret: Constants.CLIENT_SECRET,
    }

    let body = _.pick(requestBody, ApiHelper(client, 'RECEIPT_LIST').API_BODY)

    ApiHelper(client, 'RECEIPT_LIST').API(client, body)
      .then((response) => {
        if (response.status === Constants.CO_API.SUCCESS) {
          setState(prevState => ({
            ...prevState,
            invoiceData: response.data
          }))
        } else {
          showToastNotification({
            message: response.message,
            isSuccess: false,
          })
        }
      })
      .catch((err) => {
        console.log('receipt list failed: ', err)
        showToastNotification({
          message: err.message,
          isSuccess: false,
        })
      })
  }, [data.user_id, client, data._id])

  useEffect(() => {
    getInvoices()
  }, [getInvoices])

  const getTable = () => {
    const table = IndividualUserConstants.RECEIPT_TABLE_COLUMNS[client]
    return (
      <Table
        striped
        hover
        className="p-1 bg-white table-responsive receipt-table"
      >
        <thead>
          <tr>
            {table.map((opts, idx) => (
              <th key={idx}>
                <span className={classNames(`mulitline-header ${opts.accessorKey}`)}>
                  {opts.header}
                </span>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {state.invoiceData.map((rowData, rowIndex) => (
            <tr key={rowIndex}>
              {table.map((opts, colIndex) => {
                const accessorValue = _.get(rowData, opts.accessorKey);
                return (
                  <td key={colIndex}>
                    {UserInfoFormatters[opts.formatter]({
                      client,
                      value: accessorValue,
                      rowData,
                      userData: data,
                      jwtToken,
                    })}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </Table>
    )
  }

  const getBillingAddress = () => {
    let address = data.address

    if (_.isEmpty(address))
      return null

    return (
      <div className='d-flex flex-column'>
        <h6 className='fw-bold'>Billing Address</h6>
        <span>{_.startCase(address.name)}</span>
        <span>{address.line1}, {address.line2}</span>
        <span>{address.country.label} - {address.postal_code}</span>
        {
          address.taxId
            ?
            <span>
              {
                address.country.label.toLowerCase() === 'india'
                  ? <>GST: </>
                  : <>Tax: </>
              }
              <span>{address.taxId}</span>
            </span>
            : null
        }
      </div>
    )
  }

  return (
    <>
      <div className="invoice-recept-tab">
        <div className="d-flex justify-content-between">
          {getBillingAddress()}
          <div className="text-end flex-grow-1 align-self-end">
            {_.includes(IndividualUserConstants.INVOICE_AND_RECEIPT_TAB[client].SHOW_BUTTONS, 'generate_receipt') &&
              data.activated_on && data.status === 'active' ? (
                <MarkAsPaid
                  data={data}
                  brand="clearout"
                  getUserInfo={getUserInfo}
                  getInvoices={getInvoices}
                  client={client}
                />
              ) : null}
          </div>
        </div>
      </div>
      <div className='invoice-recept-table'>
        {getTable()}
      </div>
    </>
  );
};

export default InvoicesAndReceipts
